var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "p-0 px-2" },
        [
          _c(
            "v-row",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "10" } },
                [
                  _c(
                    "div",
                    { staticClass: "bg-white border p-5" },
                    [
                      _c("h1", { staticClass: "display-3" }, [
                        _vm._v(_vm._s(_vm.$t("Applicationform"))),
                      ]),
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          _vm._s(_vm.$t("NewSuppliersCanRegisterOnThisPage"))
                        ),
                      ]),
                      _c("hr"),
                      _c("p", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("GeneralInformation"))),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("CompanyName")) + " "),
                              _c("v-text-field", {
                                ref: "companyname",
                                attrs: {
                                  id: "companyname",
                                  error: _vm.errors.companyname,
                                  disabled: _vm.disabled.companyname,
                                  dense: "",
                                  outlined: "",
                                  messages:
                                    _vm.$t("Info") +
                                    ": " +
                                    _vm.$t(
                                      "DoNotUseAbbreviationsOnCompanyName"
                                    ),
                                  color: "primary",
                                  placeholder: _vm.$t("CompanyName"),
                                  "background-color": _vm.disabled.companyname
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: { blur: _vm.handleBlurCompanyName },
                                model: {
                                  value: _vm.model.companyname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "companyname", $$v)
                                  },
                                  expression: "model.companyname",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("TurkeyBasedAbroad")) + " "
                              ),
                              _c("v-select", {
                                ref: "turkeybasedabroad",
                                attrs: {
                                  id: "turkeybasedabroad",
                                  items: _vm.items.turkeybasedabroad,
                                  "item-text": "text",
                                  "item-value": "value",
                                  error: _vm.errors.turkeybasedabroad,
                                  disabled: _vm.disabled.turkeybasedabroad,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("TurkeyBasedAbroad"),
                                  "background-color": _vm.disabled
                                    .turkeybasedabroad
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.Changed_TurkeyBasedAbroad()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(data.item.text)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c("v-list-item", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(data.item.text)) +
                                              " "
                                          ),
                                        ]),
                                        _c("v-divider", {
                                          staticClass: "mt-2",
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.turkeybasedabroad,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "turkeybasedabroad",
                                      $$v
                                    )
                                  },
                                  expression: "model.turkeybasedabroad",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("IsSoleTrader")) + " "
                              ),
                              _c("v-select", {
                                ref: "issoletrader",
                                attrs: {
                                  id: "issoletrader",
                                  items: _vm.items.issoletrader,
                                  "item-text": "text",
                                  "item-value": "value",
                                  error: _vm.errors.issoletrader,
                                  disabled: _vm.disabled.issoletrader,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("IsSoleTrader"),
                                  "background-color": _vm.disabled.issoletrader
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.Changed_IsSoleTrader()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(data.item.text)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c("v-list-item", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(data.item.text)) +
                                              " "
                                          ),
                                        ]),
                                        _c("v-divider", {
                                          staticClass: "mt-2",
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.issoletrader,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "issoletrader", $$v)
                                  },
                                  expression: "model.issoletrader",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t(_vm.customtext.vknortckn)) +
                                  " "
                              ),
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.masktvknortckn,
                                    expression: "masktvknortckn",
                                  },
                                ],
                                ref: "vknortckn",
                                attrs: {
                                  id: "vknortckn",
                                  error: _vm.errors.vknortckn,
                                  disabled: _vm.disabled.vknortckn,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t(_vm.customtext.vknortckn),
                                  "background-color": _vm.disabled.vknortckn
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                model: {
                                  value: _vm.model.vknortckn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "vknortckn", $$v)
                                  },
                                  expression: "model.vknortckn",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("sector")) + " "),
                              _c("v-autocomplete", {
                                ref: "ortaksektoradi",
                                attrs: {
                                  id: "ortaksektoradi",
                                  items: _vm.items.ortaksektoradi,
                                  "item-text": "value",
                                  "item-value": "text",
                                  error: _vm.errors.ortaksektoradi,
                                  disabled: _vm.disabled.ortaksektoradi,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "prepend-inner-icon": "mdi-database-search",
                                  "append-icon": "",
                                  color: "primary",
                                  placeholder: _vm.$t("sector"),
                                  "background-color": _vm.disabled
                                    .ortaksektoradi
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: { change: _vm.Changed_Ortaksektoradi },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.text
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.ortaksektoradi,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "ortaksektoradi", $$v)
                                  },
                                  expression: "model.ortaksektoradi",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("p", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("ContactInformation"))),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("EmailAdress")) + " "),
                              _c("v-text-field", {
                                ref: "email",
                                attrs: {
                                  id: "email",
                                  error: _vm.errors.email,
                                  disabled: _vm.disabled.email,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("EmailAdress"),
                                  "background-color": _vm.disabled.email
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: { blur: _vm.handleBlurEmail },
                                model: {
                                  value: _vm.model.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "email", $$v)
                                  },
                                  expression: "model.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("CompanyExecutiveName")) +
                                  " "
                              ),
                              _c("v-text-field", {
                                ref: "executivename",
                                attrs: {
                                  id: "executivename",
                                  error: _vm.errors.executivename,
                                  disabled: _vm.disabled.executivename,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("CompanyExecutiveName"),
                                  "background-color": _vm.disabled.executivename
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                model: {
                                  value: _vm.model.executivename,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "executivename", $$v)
                                  },
                                  expression: "model.executivename",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("CompanyExecutiveSurname")) +
                                  " "
                              ),
                              _c("v-text-field", {
                                ref: "executivesurname",
                                attrs: {
                                  id: "executivesurname",
                                  error: _vm.errors.executivesurname,
                                  disabled: _vm.disabled.executive,
                                  name: "",
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t(
                                    "CompanyExecutiveSurname"
                                  ),
                                  "background-color": _vm.disabled
                                    .executivesurname
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                model: {
                                  value: _vm.model.executivesurname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "executivesurname", $$v)
                                  },
                                  expression: "model.executivesurname",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", md: "3" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("Phonecode")) + " "),
                              _c("v-autocomplete", {
                                ref: "phonecode",
                                attrs: {
                                  id: "phonecode",
                                  items: _vm.items.phonecode,
                                  "item-text": "text",
                                  "item-value": "value",
                                  error: _vm.errors.phonecode,
                                  disabled: _vm.disabled.phonecode,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "prepend-inner-icon": "mdi-database-search",
                                  "append-icon": "",
                                  color: "primary",
                                  placeholder: _vm.$t("Phonecode"),
                                  filter: _vm.Filter_Phonecode,
                                  "background-color": _vm.disabled.phonecode
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.Changed_Phonecode()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.ulke_en
                                                                          ) +
                                                                          " | " +
                                                                          _vm._s(
                                                                            item.text
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.phonecode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "phonecode", $$v)
                                  },
                                  expression: "model.phonecode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8", md: "9" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("Gsm")) + " "),
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.maskgsm,
                                    expression: "maskgsm",
                                  },
                                ],
                                ref: "gsm",
                                attrs: {
                                  id: "gsm",
                                  error: _vm.errors.gsm,
                                  disabled: _vm.disabled.gsm,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("Gsm"),
                                  "background-color": _vm.disabled.gsm
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                model: {
                                  value: _vm.model.gsm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "gsm", $$v)
                                  },
                                  expression: "model.gsm",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("Country")) + " "),
                              _c("v-autocomplete", {
                                ref: "country",
                                attrs: {
                                  id: "country",
                                  items: _vm.items.country,
                                  "item-text": "text",
                                  "return-object": "",
                                  error: _vm.errors.country,
                                  disabled: _vm.disabled.country,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "prepend-inner-icon": "mdi-database-search",
                                  "append-icon": "",
                                  color: "primary",
                                  placeholder: _vm.$t("Country"),
                                  filter: _vm.Filter_Country,
                                  "background-color": _vm.disabled.country
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.Changed_Country()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.text
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "country", $$v)
                                  },
                                  expression: "model.country",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("City")) + " "),
                              _c("v-autocomplete", {
                                ref: "province",
                                attrs: {
                                  id: "province",
                                  items: _vm.items.province,
                                  "item-text": "text",
                                  "item-value": "value",
                                  error: _vm.errors.province,
                                  disabled: _vm.disabled.province,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "prepend-inner-icon": "mdi-database-search",
                                  "append-icon": "",
                                  color: "primary",
                                  placeholder: _vm.$t("City"),
                                  filter: _vm.Filter_Province,
                                  "background-color": _vm.disabled.province
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.Changed_Province()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.text
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.province,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "province", $$v)
                                  },
                                  expression: "model.province",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("County")) + " "),
                              _c("v-autocomplete", {
                                ref: "district",
                                attrs: {
                                  id: "district",
                                  items: _vm.items.district,
                                  "item-text": "text",
                                  "item-value": "value",
                                  error: _vm.errors.district,
                                  disabled: _vm.disabled.district,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "prepend-inner-icon": "mdi-database-search",
                                  "append-icon": "",
                                  color: "primary",
                                  placeholder: _vm.$t("County"),
                                  filter: _vm.Filter_District,
                                  "background-color": _vm.disabled.district
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ active, item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.text
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.model.district,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "district", $$v)
                                  },
                                  expression: "model.district",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("Address")) + " "),
                              _c("v-textarea", {
                                ref: "address",
                                attrs: {
                                  id: "address",
                                  error: _vm.errors.address,
                                  disabled: _vm.disabled.address,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  placeholder: _vm.$t("Address"),
                                  "background-color": _vm.disabled.address
                                    ? "grey lighten-3"
                                    : "white",
                                },
                                model: {
                                  value: _vm.model.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "address", $$v)
                                  },
                                  expression: "model.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "mt-6 d-flex justify-content-center align-items-center",
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mr-0 pr-0",
                              attrs: { cols: "auto" },
                            },
                            [
                              _vm.captchaUrl.length == 0
                                ? _c("v-skeleton-loader", {
                                    attrs: {
                                      "max-width": "240",
                                      "max-height": "70",
                                      "min-width": "240",
                                      "min-height": "70",
                                      type: "image",
                                    },
                                  })
                                : _c("img", {
                                    style: {
                                      filter:
                                        "blur(" +
                                        (_vm.captchaBlur ? "5px" : "0px") +
                                        ")",
                                    },
                                    attrs: {
                                      alt: "Captcha",
                                      src: _vm.captchaUrl,
                                      width: "240",
                                      height: "70",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    icon: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.ReloadCaptcha()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.captchaBlur ? "mdi-eye" : "cached"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("SecurityCode")) + " "),
                      _c("v-text-field", {
                        attrs: {
                          error: _vm.errors.captcha,
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          color: "primary",
                          "append-icon": "mdi-shield-check",
                          placeholder: _vm.$t("SecurityCode"),
                        },
                        model: {
                          value: _vm.model.captcha,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "captcha", $$v)
                          },
                          expression: "model.captcha",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-5", attrs: { cols: "12" } },
                            [
                              _c(
                                "table",
                                [
                                  _vm._l(_vm.documents, function (document, i) {
                                    return _c(
                                      "tr",
                                      {
                                        key: i,
                                        staticClass:
                                          "my-0 py-0 d-flex align-center",
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-flex align-center my-0 py-0",
                                          },
                                          [
                                            _c("v-checkbox", {
                                              staticClass: "my-0 py-0",
                                              attrs: {
                                                error: document.error,
                                                color: "primary",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prepend",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.PrepDocument(
                                                                  document.title
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-file-document-outline"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2",
                                                            staticStyle: {
                                                              "padding-top":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  document.title +
                                                                    "Description"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: document.selected,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    document,
                                                    "selected",
                                                    $$v
                                                  )
                                                },
                                                expression: "document.selected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }),
                                  _c("tr", [
                                    _c("td", [
                                      _c("p", {
                                        staticClass: "caption",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "ApplicationBottomDescription"
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-alert",
                        {
                          attrs: { type: "error" },
                          model: {
                            value: _vm.alert,
                            callback: function ($$v) {
                              _vm.alert = $$v
                            },
                            expression: "alert",
                          },
                        },
                        _vm._l(_vm.ErrorText, function (Err, i) {
                          return _c("span", { key: i }, [
                            _vm._v(" " + _vm._s(_vm.$t(Err))),
                            _c("br"),
                          ])
                        }),
                        0
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.processing,
                            depressed: "",
                            block: "",
                            tile: "",
                            color: "primary white--text",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.Application()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("MakeAnApplication")))]
                      ),
                    ],
                    1
                  ),
                  _vm.processing
                    ? _c("v-progress-linear", {
                        attrs: { color: "primary", indeterminate: "" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              false
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-content-center mb-6",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("p", {
                        staticClass: "mb-0 mt-6 pb-0",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("ForYourQuestionsAndSuggestions")
                          ),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "390" },
          model: {
            value: _vm.isAnyError,
            callback: function ($$v) {
              _vm.isAnyError = $$v
            },
            expression: "isAnyError",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto pt-7 pb-2" },
            [
              _c("alertIcon", { attrs: { icon: "error" } }),
              _c("v-card-title", { staticClass: "mb-0 text-center" }, [
                _c("p", { staticClass: "mx-auto headline pb-0 mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("Warning"))),
                ]),
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.ErrorText, function (Err, i) {
                  return _c("span", { key: i }, [
                    _vm._v(" " + _vm._s(_vm.$t(Err))),
                    _c("br"),
                  ])
                }),
                0
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text font-weight-bolder px-5",
                      attrs: { text: "", color: "red" },
                      on: {
                        click: function ($event) {
                          _vm.isAnyError = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.processing,
            callback: function ($$v) {
              _vm.processing = $$v
            },
            expression: "processing",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-5", attrs: { color: "transparent", dark: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "text-center py-5" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: 50, color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", scrollable: "" },
          model: {
            value: _vm.Checkbox.Model,
            callback: function ($$v) {
              _vm.$set(_vm.Checkbox, "Model", $$v)
            },
            expression: "Checkbox.Model",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.Checkbox.DocumentTitle))),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.Checkbox.Model = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c("v-card-text", {
                staticStyle: { height: "600px" },
                domProps: {
                  innerHTML: _vm._s(_vm.$t(_vm.Checkbox.DocumentContent)),
                },
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize px-5",
                      attrs: {
                        text: "",
                        block: "",
                        tile: "",
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.DownloadDocument()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.$t("Download"))),
                      ]),
                      _c("v-icon", { attrs: { small: "", right: "" } }, [
                        _vm._v("mdi-download"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }